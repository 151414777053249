.appdiv {
  display: grid;
  place-items: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  /* background-color: rgb(252, 97, 97); */
  /* background-color: rgb(66, 65, 65); */
  background-color: rgb(241, 241, 241);
}
.appdivnew {
  display: grid;
  place-items: center;
  align-content: center;
  height: 100%;
  width: 50%;
  /* background-color: rgb(252, 97, 97); */
  /* background-color: rgb(66, 65, 65); */
  background-color: rgb(241, 241, 241);
}
.appdivnew2 {
  width: 50%;
  height: 100%;
  border-left: 3px solid rgb(205, 203, 203);
  padding: 2%;
}
.appdiv h2 {
  color: gray;
}
.p-changepwd {
  /* color: white; */
  color: gray;
  margin-top: 3%;
  text-decoration: underline;
  cursor: pointer;
  margin-left: -3.5%;
}
.alertform {
  width: 45%;
  height: 60%;
  position: absolute;
  z-index: 1000;
  margin-top: 10%;
  margin-left: 27%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  box-shadow: 0 0 200px 200px rgba(189, 189, 189, 0.5);
}
.alertform form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}
.appdiv div label {
  color: gray;
}

@media (max-width: 991px) {
  .alertform {
    width: 98%;
    margin: 0%;
    height: 50%;
    margin-top: 35%;
  }
}

.test{
  color: rgb(176, 171, 165);
}
